var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.$route.name != 'subjects')?_c('router-view'):_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h4 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$tc("subject.label", _vm.subjects.length))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","to":{ name: 'newSubject' },"small":""}},[_vm._v(_vm._s(_vm.$t("global.add")))])],1),(_vm.subjects.length > 0)?_c('v-card-text',[_c('paginate',{attrs:{"items":_vm.subjects,"perPage":10},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-list',[_vm._l((items),function(subject){return [_c('v-list-item',{key:subject.name,staticClass:"task-item-list mb-2",attrs:{"to":{
                    name: 'subjectDisplay',
                    params: {
                      subject_id: subject.id,
                    },
                  }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":subject.color}},[_vm._v("mdi-square")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(subject.name)+" ")])],1),_c('v-list-item-action-text',[_c('v-btn',{attrs:{"text":"","color":"error","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.remove(subject)}}},[_vm._v(" "+_vm._s(_vm.$t("global.delete"))+" ")])],1)],1)]})],2)]}}],null,false,450921621)})],1):_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("subject.empty"))+" ")]),_c('confirm-modal',{ref:"confirm"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }