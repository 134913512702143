






























































import subjectModule from "@/store/modules/subjects";
import { Subject } from "@/types/subject";
import SubjectDetails from "@/components/subject/SubjectDetails.vue";
import Factory from "@/types/Factory";
import Paginate from "@/components/utility/Paginate.vue";
import { Ref, Vue, Component } from "vue-property-decorator";
import ConfirmModal from "@/components/utility/ConfirmModal.vue";

@Component({
  components: {
    SubjectDetails,
    Paginate,
    ConfirmModal,
  },
})
export default class Subjects extends Vue {
  @Ref() readonly subjectForm!: SubjectDetails;
  @Ref() readonly confirm!: ConfirmModal;
  subject = Factory.getSubject();

  get subjects(): Subject[] {
    return subjectModule.subjects;
  }

  async remove(subject: Subject): Promise<void> {
    try {
      const reply = await this.confirm.open();
      if (reply) {
        await subjectModule.delete(subject);
        this.$toast.success(this.$t("global.success").toString());
      }
    } catch (err) {
      this.$toast.error(err.response.data);
    }
  }
}
